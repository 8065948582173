import React, { useEffect, useState } from 'react';
import {
	Card,
	Checkbox,
	Chip,
	Divider,
	IconButton,
	Link,
	Popover,
	Typography,
	Zoom,
} from '@mui/material';
import {
	ShareRounded,
	OpenInNewRounded,
	SwapHorizRounded,
} from '@mui/icons-material/';
import { useTranslation } from 'react-i18next';
import { OfferToolTip } from '../../../../helpers';
import { Column, Line } from '../../../../styles';
import { usePO } from '../../../../utils/POContext';
import CardDenounceConfirmationDialog from '../CardDenounceConfirmationDialog';
import PlatformIcon from '../../../../components/PlatformIcon';
import { useCardSelector } from '../../../../hooks/Classificacoes/CardSelector';
import TagSelector from '../../../../components/TagSelector';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { ErrorResponse, TagObjectType } from '../../../../interfaces';
import { api, isAxiosError } from '../../../../utils/api';
import LoadingDots from '../../../../components/LoadingDots';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import MultiItemCarousel from '../../MultiItemCarouselDisplay';

interface Props {
	data: any;
	handleFlip: () => void;
	className: any;
	handleSetTags: (cardTags) => void;
	cardAsGood: boolean;
	handleRemoveCardAsGood: () => void;
	index: number;
	offersDataPackage: object[];
}

const CardBack: React.FC<Props> = ({
	data,
	handleFlip,
	className,
	handleSetTags,
	cardAsGood,
	handleRemoveCardAsGood,
	index,
	offersDataPackage,
}) => {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const { selectedTheme, tagsActiveClient, selectedClient, userData } = usePO();
	const { selectedCards, handleSelectCard } = useCardSelector();

	const [openAnalisysModal, setOpenAnalisysModal] = useState(false);
	const [cardTags, setCardTags] = useState<any[]>([]);
	const [lastTags, setLastTags] = useState<TagObjectType[]>([]);
	const [openMultiOfferModal, setOpenMultiOfferModal] = useState(false);
	const [loadingTags, setLoadingTags] = useState(false);

	const goodCardsList = useSelector(
		(state: any) => state.cardFeedback.goodCardsList
	);

	useEffect(() => {
		if (data?.tags) {
			setTimeout(() => {
				setCardTags(filteredClientTags);
			}, 100);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedClient?.id]);

	const filteredClientTags = data?.tags?.filter((tag) => {
		return tag.clientID === selectedClient.id;
	});

	const handleCopyLinkToURL = () => {
		const { protocol, host } = window.location;
		const url = `${protocol}//${host}/offerID/${data.offerID}`;
		navigator.clipboard
			.writeText(url)
			.then(() => {
				enqueueSnackbar(
					t('Classificações.Link copiado para a área de transferência'),
					{ variant: 'success' }
				);
			})
			.catch((err) => {
				console.error('Error copying to clipboard', err);
			});
	};

	const handleSelect = (event) => {
		handleSelectCard(
			{ offerID: data.offerID, tags: data.tags },
			event.target.checked ? 'add' : 'remove'
		);
	};

	const handleVerifyCheckBox = () => {
		if (data.author.authorData?.official?.value) {
			return false;
		} else if (
			goodCardsList.includes(data.offerID) ||
			data.clientsClassifiedAsGood?.includes(selectedClient?.id)
		) {
			return false;
		}
		return true;
	};

	const checkPermission = () => {
		if (userData.roles[0] === 'Denunciante') {
			return false;
		} else {
			return true;
		}
	};

	const handleLineColor = () => {
		if (data.offerRisk) {
			return selectedTheme.error;
		}
		if (
			goodCardsList.includes(data.offerID) ||
			data.author.authorData.official?.value ||
			cardAsGood
		) {
			return selectedTheme.success;
		}
		return 'transparent';
	};

	const handleSaveTags = async () => {
		const success: any = [];
		const error: any = [];
		const payload = {
			offerIDs: [data.offerID],
			tagIDs: cardTags.map((el) => el.tagID),
		};
		try {
			setLoadingTags(true);
			const tagsResponse = await api.patch(
				`/tagINoffer/elasticsearch?clientID=${selectedClient.id}`,
				payload,
				{
					timeout: 300 * 1000,
				}
			);
			tagsResponse.data.map((el) => {
				if (el.status >= 400) {
					error.push(el.detail);
				} else {
					success.push(el.detail);
				}
				return null;
			});
			handleSetTags(cardTags);
			setLoadingTags(false);
			setLastTags(cardTags);
			enqueueSnackbar(success[0], { variant: 'success' });
			if (error.length > 0) {
				setTimeout(() => {
					enqueueSnackbar(error[0], { variant: 'error' });
				}, 3000);
			}
		} catch (error) {
			if (isAxiosError(error)) {
				const errorResponse = error.response as ErrorResponse | undefined;
				if (errorResponse && errorResponse.data) {
					enqueueSnackbar(errorResponse?.data?.detail, { variant: 'error' });
				}
			}
		} finally {
			setCardTags(lastTags);
			setLoadingTags(false);
		}
	};

	const renderCardHeader = () => (
		<Line
			style={{
				justifyContent: 'space-between',
				position: 'absolute',
				top: 0,
				padding: '3px 7px',
			}}
		>
			<IconButton
				size="small"
				onClick={handleFlip}
				data-testid="cardFlipBack"
				sx={{
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
			>
				<SwapHorizRounded
					style={{
						zIndex: 10000,
						color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
					}}
				/>
			</IconButton>
			<OfferToolTip
				title={
					<Typography variant="subtitle2" align="center">{`${t(
						'Classificações.Abrir anúncio na plataforma de origem'
					)} (${data.platformINcountryID?.replace('|', ' - ')})`}</Typography>
				}
				aria-label="inativo"
				enterDelay={700}
				enterNextDelay={700}
				arrow
				TransitionComponent={Zoom}
			>
				<Link href={data.permalink} target="blank">
					<PlatformIcon
						data={data.platformINcountryID}
						dataType="platform"
						size={24}
						cursor="pointer"
					/>
				</Link>
			</OfferToolTip>
		</Line>
	);

	const renderCardInfo = () => (
		<Column style={{ padding: 10, height: 210, marginLeft: 25 }}>
			<Typography
				variant="subtitle1"
				style={{
					color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
				}}
			>
				{`${t('Classificações.Descrição')}:`}
			</Typography>
			<Typography
				variant="caption"
				style={{
					color: selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
					maxHeight: 166,
					width: 210,
					marginTop: 5,
					overflowY: 'auto',
					overflowX: 'hidden',
					whiteSpace: 'pre-line',
				}}
			>
				{data.description}
			</Typography>
		</Column>
	);

	const renderTagSelector = () => (
		<PopupState variant="popover" popupId="demo-popup-popover">
			{(popupState) => (
				<Column style={{ alignItems: 'start', padding: '0 20px', rowGap: 5 }}>
					<Typography
						variant="subtitle2"
						style={{
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
						}}
					>
						{`${t('Classificações.Etiquetas')}`}:
					</Typography>
					<Line
						{...bindTrigger(popupState)}
						style={{
							maxHeight: 40,
							marginTop: -3,
							width: '100%',
							flexWrap: 'wrap',
							alignItems: 'start',
							borderStyle: 'solid',
							borderWidth: 1,
							borderColor:
								selectedTheme.tone === 'dark' ? '#707070' : '#cacaca',
							borderRadius: 4,
							overflow: 'hidden',
						}}
					>
						<div style={{ height: 30, width: 1, top: 0, left: 0 }}></div>
						{cardTags.length > 0 ? (
							<>
								<Chip
									key={cardTags[0]?.name}
									variant="outlined"
									label={cardTags[0]?.name}
									style={{
										height: '30px',
										margin: '4px',
										backgroundColor: cardTags[0]?.badgeColor,
										borderColor: selectedTheme.id === 'dark' ? '' : '#e8e8e8',
										color: cardTags[0]?.textColor,
										borderRadius: 6,
										fontSize: 15,
										maxWidth: '75%',
									}}
								/>
								{cardTags.length > 1 ? (
									<Typography style={{ padding: 8 }}>{`+ ${
										cardTags.length - 1
									} `}</Typography>
								) : null}
								<div
									style={{
										position: 'absolute',
										right: 17,
										bottom: 50,
										display: loadingTags ? 'block' : 'none',
									}}
								>
									<LoadingDots width={30} loop />
								</div>
							</>
						) : null}
					</Line>
					<Popover
						{...bindPopover(popupState)}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}
						sx={{
							'.MuiPaper-root': {
								background:
									selectedTheme.id === 'dark' && selectedTheme.overlay6dp,
							},
						}}
					>
						<Column
							style={{
								padding: '20px 30px',
								height: '100%',
								alignItems: 'start',
								justifyContent: 'space-between',
								rowGap: 10,
							}}
						>
							<Line>
								<Typography
									style={{
										color:
											selectedTheme.id === 'dark' &&
											selectedTheme.textColorHigh,
									}}
								>
									{`${t('Classificações.Adicionar Etiquetas')}`}:
								</Typography>
								<div
									style={{
										position: 'absolute',
										right: 16,
										display: loadingTags ? 'block' : 'none',
									}}
								>
									<LoadingDots width={50} loop />
								</div>
							</Line>
							<TagSelector
								setDataArray={setCardTags}
								dataArray={cardTags}
								handleSaveTags={() => handleSaveTags()}
								suggestions={tagsActiveClient}
								loading={loadingTags}
								autoSave
								rows={1}
								maxItems={6}
								width={'250px'}
							/>
						</Column>
					</Popover>
				</Column>
			)}
		</PopupState>
	);

	const renderCardFooter = () => (
		<Line
			style={{
				justifyContent: 'space-between',
				padding: '4px 10px 4px 8px',
				width: 250,
			}}
		>
			<Line style={{ justifyContent: 'flex-start' }}>
				{checkPermission() && handleVerifyCheckBox() ? (
					<Checkbox
						size="small"
						checked={
							selectedCards.find((card) => card.offerID === data.offerID)
								? true
								: false
						}
						onClick={handleSelect}
						style={{
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.primaryDark,
						}}
						sx={{
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					/>
				) : null}
				<OfferToolTip
					title={`${t('Classificações.Compartilhar')}`}
					aria-label="inativo"
					enterDelay={700}
					enterNextDelay={700}
					arrow
					TransitionComponent={Zoom}
				>
					<IconButton
						onClick={handleCopyLinkToURL}
						sx={{
							padding: '6px',
							marginLeft: '-5px',
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						<ShareRounded
							style={{
								fontSize: 20,
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: selectedTheme.primaryDark,
							}}
						/>
					</IconButton>
				</OfferToolTip>
				<OfferToolTip
					title={`${t('Classificações.Abrir tela de classificação')}`}
					aria-label="inativo"
					enterDelay={700}
					enterNextDelay={700}
					arrow
					TransitionComponent={Zoom}
				>
					<IconButton
						onClick={() => setOpenAnalisysModal(!openAnalisysModal)}
						sx={{
							padding: '6px',
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						<OpenInNewRounded
							style={{
								fontSize: 20,
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: selectedTheme.primaryDark,
							}}
						/>
					</IconButton>
				</OfferToolTip>
			</Line>
			{checkPermission() ? (
				<Line style={{ justifyContent: 'flex-end' }}>
					<CardDenounceConfirmationDialog
						cardAsGood={cardAsGood}
						handleRemoveCardAsGood={handleRemoveCardAsGood}
						offerData={data}
					/>
				</Line>
			) : null}
		</Line>
	);

	const renderCardRightLine = () => (
		<Column
			style={{
				width: 3,
				height: 330,
				background: handleLineColor(),
				opacity: 0.7,
			}}
		/>
	);

	return (
		<>
			<Card
				className={className}
				style={{
					width: 250,
					height: 330,
					backgroundColor:
						selectedTheme.id === 'dark' && selectedTheme.overlay4dp,
				}}
			>
				<Line>
					{renderCardRightLine()}
					<div style={{ width: 330, marginLeft: -2 }}>
						<Line>
							<div style={{ width: 199 }}>
								{renderCardHeader()}
								{renderCardInfo()}
							</div>
						</Line>
						{renderTagSelector()}
						<div
							style={{
								marginTop: cardTags.length > 0 ? 8 : 16,
							}}
						>
							<Divider
								sx={{ opacity: 0.6 }}
								style={{
									margin: '0px 15px',
									background:
										selectedTheme.id === 'dark' && selectedTheme.footerLine,
								}}
							/>
							{renderCardFooter()}
						</div>
					</div>
				</Line>
			</Card>
			<MultiItemCarousel
				index={index}
				offersDataPackage={offersDataPackage}
				open={openMultiOfferModal}
				setOpen={setOpenMultiOfferModal}
			/>
		</>
	);
};
export default CardBack;
