import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OfferTable from '../../components/OfferTable';
import { usePO } from '../../utils/POContext';
import { useSnackbar } from 'notistack';
import { getClientData, getClients } from '../../services/client';
import { OfferToolTip } from '../../helpers';
import { IconButton, Typography, Zoom } from '@mui/material';
import {
	AddCircleRounded,
	Cached,
	CancelRounded,
	CheckCircleRounded,
} from '@mui/icons-material/';

import ClientBranchStatusChangeDialog from './components/ClientBranchStatusChangeDialog';
import ClientEdit from './components/ClientEditDialog';
import ClientStatusChangeDialog from './components/ClientStatusChangeDialog';
import { Line } from '../../styles';
import Unauthorized from '../Page401';
import ClientNewForm from './ClientNewForm';
import ClientAddBranch from './components/ClientAddBranch';
import ClientEditBranch from './components/ClientEditBranch';
import ClientEditReasons from './components/ClientEditReasons';

interface ClientRowDataType {
	id: string;
	name: string;
	description: string;
	referenceCode: string;
	ISactive: boolean;
	countryID: string;
	clientID: string;
	cityName: string;
	BIID: string;
	parent: string;
}

interface ClientRowDataExpansiveType {
	id: string;
	name: string;
	description: string;
	referenceCode: string;
	ISactive: boolean;
	countryID: string;
	clientID: string;
	cityName: string;
	clientRegNumber: string;
	country: string;
	streetAddress: string;
	numberAddress: string;
	stateName: string;
	complement: string;
	contactName: string;
	phone: string;
	email: string;
	zipCode: string;
	status: React.JSX.Element | null;
	BIID: string;
}

const ManageClients: React.FC = () => {
	const { userData, selectedTheme } = usePO();
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();

	const [loading, setLoading] = useState(true);
	const [tableData, setTableData] = useState<ClientRowDataType[]>([
		{
			id: '',
			name: '',
			description: '',
			referenceCode: '',
			ISactive: false,
			countryID: '',
			clientID: '',
			cityName: '',
			BIID: '',
			parent: '',
		},
	]);
	const [clientsModalOpen, setClientsModalOpen] = useState<boolean>(false);

	// eslint-disable-next-line @typescript-eslint/no-var-requires
	const countries = require('i18n-iso-countries');
	countries.registerLocale(
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		require('i18n-iso-countries/langs/pt.json')
	);
	countries.registerLocale(
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		require('i18n-iso-countries/langs/en.json')
	);
	countries.registerLocale(
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		require('i18n-iso-countries/langs/es.json')
	);

	const textIconStyleHeader = () => {
		switch (selectedTheme.id) {
			case 'main':
				return selectedTheme.primary;
			case 'dark':
				return selectedTheme.textColorHigh;
			default:
				return selectedTheme.foreground;
		}
	};

	useEffect(() => {
		fetchData();
		// eslint-disable-next-line
	}, []);

	const fetchData = async () => {
		setLoading(true);
		const response: any = await getClients();
		if (response.success === true) {
			buildTableData(response.data);
		}
		if (response.success === false) {
			enqueueSnackbar(t(response.message), { variant: 'error' });
		}
		setLoading(false);
	};

	const handleNewClient = () => {
		setClientsModalOpen(true);
	};

	const colsData = [
		{
			columnTitle: t('Clientes.Nome'),
			fieldName: 'name',
			translateColumnData: false,
			alignTitle: 'center',
			cellWidth: 200,
			dataType: 'string',
		},

		{
			columnTitle: t('Clientes.Código do Cliente'),
			fieldName: 'referenceCode',
			translateColumnData: false,
			alignTitle: 'center',
			cellWidth: 100,
			dataType: 'string',
		},
		{
			columnTitle: t('Clientes.Descrição'),
			fieldName: 'description',
			translateColumnData: false,
			alignTitle: 'center',
			cellWidth: 100,
			dataType: 'string',
		},
		{
			columnTitle: t('Clientes.Pai'),
			fieldName: 'parent',
			translateColumnData: false,
			alignTitle: 'center',
			cellWidth: 200,
			dataType: 'string',
		},
	];

	const expandedColsData = [
		{
			columnTitle: t('Clientes.Unidade'),
			fieldName: 'country',
			translateColumnData: false,
			alignTitle: 'center',
			cellWidth: 100,
		},
		{
			columnTitle: t('Clientes.Cidade'),
			fieldName: 'cityName',
			translateColumnData: false,
			alignTitle: 'center',
			cellWidth: 50,
		},
		{
			columnTitle: t('Clientes.Número de registro no país'),
			fieldName: 'clientRegNumber',
			translateColumnData: false,
			alignTitle: 'center',
			cellWidth: 80,
		},
		{
			columnTitle: t('Clientes.Status'),
			fieldName: 'status',
			translateColumnData: false,
			alignTitle: 'center',
			marginTitle: '0 0 0 -25px',
			cellWidth: 50,
			dataType: 'reactObject',
		},
	];

	const buildTableData = async (data: ClientRowDataType[]) => {
		if (data) {
			setTableData([]);
			const tempTableData: ClientRowDataType[] = [];
			data.forEach((el: ClientRowDataType) => {
				const formattedClients: ClientRowDataType = {
					id: el.id,
					name: el.name,
					description: el.description,
					referenceCode: el.referenceCode,
					ISactive: el.ISactive,
					countryID: el.countryID,
					clientID: el.clientID,
					cityName: el.cityName,
					parent: el.parent,
					BIID: el.BIID,
				};
				tempTableData.push(formattedClients);
				return null;
			});
			setTableData(tempTableData);
		}
	};

	const expandedDataFetch = async (data: ClientRowDataExpansiveType) => {
		try {
			const expandedResponse: any = await getClientData(data.id);
			if (
				data &&
				expandedResponse.success &&
				expandedResponse.data &&
				expandedResponse.data.length > 0
			) {
				const tempTableData: ClientRowDataExpansiveType[] = [];
				await expandedResponse.data.forEach(
					(el: ClientRowDataExpansiveType) => {
						let clientRegNumber = el.clientRegNumber;

						if (/^\d{14}$/.test(clientRegNumber)) {
							const cnpjRegex = /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/;

							clientRegNumber = clientRegNumber.replace(
								cnpjRegex,
								'$1.$2.$3/$4-$5'
							);
						}

						if (/^\d{11}$/.test(clientRegNumber)) {
							const cpfRegex = /(\d{3})(\d{3})(\d{3})(\d{2})/;

							clientRegNumber = clientRegNumber.replace(
								cpfRegex,
								'$1.$2.$3-$4'
							);
						}
						const formatedClientData: ClientRowDataExpansiveType = {
							id: el.id,
							name: el.name,
							clientID: el.clientID,
							countryID: el.countryID,
							country: countries.getName(
								el.countryID,
								userData.i18nID.substring(0, 2),
								{
									select: 'official',
								}
							),
							cityName: el.cityName,
							clientRegNumber: clientRegNumber,
							ISactive: el.ISactive,
							status: renderStatus(el.ISactive),
							zipCode: el.zipCode,
							streetAddress: el.streetAddress,
							numberAddress: el.numberAddress,
							stateName: el.stateName,
							complement: el.complement,
							contactName: el.contactName,
							phone: el.phone,
							email: el.email,
							description: el.description,
							referenceCode: el.referenceCode,
							BIID: el.BIID,
						};
						tempTableData.push(formatedClientData);
						return null;
					}
				);
				return tempTableData;
			} else if (!expandedResponse.success) {
				enqueueSnackbar(
					t('Clientes.Relação entre cliente e país não encontrada'),
					{ variant: 'warning' }
				);
				return [{ error: true }];
			}

			return [];
		} catch (error) {
			console.error('Erro ao buscar dados expandidos:', error);
			return [];
		}
	};

	const createNewClient = () => (
		<IconButton
			style={{ borderRadius: 5, marginRight: -15 }}
			sx={{
				'&:hover': {
					backgroundColor: selectedTheme.hoverBackground,
				},
			}}
			onClick={() => handleNewClient()}
		>
			<Typography
				variant="subtitle1"
				style={{
					fontWeight: 'bold',
					color: textIconStyleHeader(),

					marginRight: 15,
					marginTop: 2,
				}}
			>
				{t('Clientes.Adicionar Cliente')}
			</Typography>
			<AddCircleRounded
				style={{
					color: textIconStyleHeader(),
				}}
			/>
		</IconButton>
	);

	const renderStatus = (status: boolean) => {
		switch (status) {
			case true:
				return (
					<Line style={{ justifyContent: 'center' }}>
						<CheckCircleRounded
							fontSize="small"
							style={{
								color: selectedTheme.success,
								marginRight: 30,
							}}
						/>
					</Line>
				);
			case false:
				return (
					<Line
						style={{
							justifyContent: 'center',
						}}
					>
						<CancelRounded
							fontSize="small"
							style={{
								color: selectedTheme.error,
								marginRight: 30,
							}}
						/>
					</Line>
				);

			default:
				break;
		}
		return null;
	};

	const tableActions = [
		{
			component: ClientEditReasons,
			props: { refresh: fetchData },
		},

		{
			component: ClientAddBranch,
			props: { refresh: fetchData },
		},
		{
			component: ClientEdit,
			props: { refresh: fetchData, availableClients: tableData },
		},
		{
			component: ClientStatusChangeDialog,
			props: { refresh: fetchData },
		},
	];

	const expansibleTableActions = [
		{
			component: ClientEditBranch,
			props: { refresh: fetchData },
		},
		{
			component: ClientBranchStatusChangeDialog,
			props: { refresh: fetchData },
			action: () => {},
		},
	];

	const renderReloadTableData = () => (
		<OfferToolTip
			title={`${t('Buscas.Tabs.Buscas Salvas.Atualizar dados da tabela')}`}
			aria-label="inativo"
			enterDelay={700}
			enterNextDelay={700}
			arrow
			TransitionComponent={Zoom}
		>
			<IconButton
				data-testid="refreshButton"
				onClick={fetchData}
				sx={{
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
			>
				<Cached
					sx={{
						animation: loading ? '$spin 2s linear infinite' : 'normal',
						'@keyframes spin': loading
							? {
									'0%': {
										transform: 'rotate(360deg)',
									},
									'100%': {
										transform: 'rotate(0deg)',
									},
								}
							: null,
						zIndex: !loading ? 2 : 'auto',
					}}
					style={{
						fontSize: 22,
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
					}}
				/>
			</IconButton>
		</OfferToolTip>
	);

	return userData.permissions?.indexOf('read:clients:admin') !== -1 ? (
		<>
			<ClientNewForm
				open={clientsModalOpen}
				setOpen={setClientsModalOpen}
				refresh={fetchData}
			/>
			<OfferTable
				denseText
				tableData={tableData}
				colsData={colsData}
				loading={loading}
				windowHeader
				windowTitle={`${t('Clientes.Gerenciamento de Clientes')}`}
				CustomHeaderComponent={createNewClient}
				tableActions={tableActions}
				tableFooter
				expansibleRows
				expandedColsData={expandedColsData}
				expandedTableActions={expansibleTableActions}
				expandedDataFetch={expandedDataFetch}
				customLeftFooterComponent={renderReloadTableData()}
			/>
		</>
	) : (
		<div style={{ marginLeft: '-75px' }}>
			<Unauthorized />
		</div>
	);
};

export default ManageClients;
